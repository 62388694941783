// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react/no-unstable-nested-components */
import { Button, Form, Input } from 'antd';
import { FormattedMessage } from 'react-intl';

import useContainer from './hook';
import type { InputPasswordProps } from './types';

function InputPassword(props: InputPasswordProps) {
  const { id, className, label, hasRecovery, hasProgress, onRecoveryLinkClick } = props;

  const { isTouchedAndError, field, help, strengthData } = useContainer(props);

  const validateStatus = isTouchedAndError ? 'error' : undefined;

  return (
    <Form.Item
      htmlFor={id}
      className={className}
      label={<FormattedMessage {...label} />}
      help={help}
      validateStatus={validateStatus}
    >
      <Input.Password
        id={id}
        size="large"
        iconRender={(visible) =>
          visible ? (
            <span>
              <i className="icon icon-eye" />
            </span>
          ) : (
            <span>
              <i className="icon icon-eye-crossed" />
            </span>
          )
        }
        {...field}
      />
      {hasRecovery && (
        <div className="main-input__password-recovery">
          <Button className="text-caption in-primary-800" type="link" onClick={onRecoveryLinkClick}>
            <FormattedMessage id="shared.forgotPassword" />
          </Button>
        </div>
      )}
      {hasProgress && (
        <div className={`main-input__password-progress main-input__password-progress--step-${strengthData.step}`} />
      )}
    </Form.Item>
  );
}

export default InputPassword;
