import { MIN_PASSWORD_LENGTH, MAX_PASSWORD_LENGTH, MAX_EMAIL_LENGTH, EMAIL_REGEX } from 'constants/app';
import yup from 'lib/yupLocalised';

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email({ id: 'shared.invalidEmail' })
    .max(MAX_EMAIL_LENGTH, { id: 'shared.emailMax' })
    .required({ id: 'shared.requiredEmail' })
    .matches(EMAIL_REGEX, { message: { id: 'shared.invalidEmail' } }),
  password: yup
    .string()
    .max(MAX_PASSWORD_LENGTH, { id: 'shared.passwordMax' })
    .min(MIN_PASSWORD_LENGTH, { id: 'shared.passwordMin' })
    .required({ id: 'shared.requiredPassword' }),
});

export default validationSchema;
