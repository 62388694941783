import { isNil } from 'ramda';
import type { AnySchema } from 'yup';

function dateComparison(this: AnySchema, ref: any) {
  return this.test({
    name: 'dateComparison',
    exclusive: false,
    message: { id: 'yup.mixed.startIsGreaterThanEnd' },
    params: {
      reference: ref.path,
    },
    test(value: Date | undefined) {
      const compared: Date = this.resolve(ref);

      if (isNil(value) || isNil(compared)) {
        return true;
      }

      if (!(value instanceof Date) || !(compared instanceof Date)) {
        return true;
      }

      return !(value.getTime() > compared.getTime());
    },
  });
}

export default dateComparison;
