import Image from 'next/image';
import Link from 'next/link';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'constants/routes';

import useContainer from './hook';

function AuthSidebar() {
  const { socialLinks } = useContainer();

  return (
    <div className="auth__side flex-column">
      <div className="mb-130">
        <Link href={ROUTES.HOME.PATH}>
          <a>
            <Image src="/images/logo_white.svg" width={120} height={46} alt="logo" />
          </a>
        </Link>
      </div>
      <div className="mb-64">
        <div className="mb-120">
          <Image src="/images/auth/icon-heart.svg" width={120} height={90} alt="icon heart" />
        </div>
        <div className="auth__descr text-center">
          <h2 className="text-headline in-accent-300 fw-600 mb-16">
            <FormattedMessage id="auth.strivingToProvideIncreasingAccess" />
          </h2>
          <div className="text-subheader in-white">
            <FormattedMessage id="auth.keptHonestThroughValueBasedQualityMeasures" />
          </div>
        </div>
      </div>
      <div className="mt-auto">
        <div className="text-caption text-center text-uppercase in-white mb-16">
          <FormattedMessage id="auth.joinUsInSocialNetworks" />
        </div>
        <div className="d-flex align-content-center justify-content-center">
          <a href={socialLinks?.facebook} className="auth__socials-item" aria-label="Facebook">
            <i className="icon icon-facebook" />
          </a>
          <a href={socialLinks?.twitter} className="auth__socials-item" aria-label="Twitter">
            <i className="icon icon-twitter" />
          </a>
          <a href={socialLinks?.instagram} className="auth__socials-item" aria-label="Instagram">
            <i className="icon icon-instagram" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default AuthSidebar;
