import type { ReactNode } from 'react';

import AuthSide from '../sidebars/AuthSide';

function AuthLayout({ children }: { children: ReactNode }) {
  return (
    <div className="main-wrap d-flex">
      <AuthSide />
      {children}
    </div>
  );
}

export default AuthLayout;
