import { Button } from 'antd';
import Image from 'next/image';
import { FormattedMessage } from 'react-intl';

interface AuthHeaderProps {
  isSignUp?: boolean;
  onClick?: () => void;
}

function AuthHeader({ isSignUp, onClick }: AuthHeaderProps) {
  return (
    <div className="flex-center-y mb-40 mb-md-160">
      <div className="d-xl-none">
        <Image src="/images/logo_dark.svg" width={100} height={38} alt="logo" />
      </div>
      <div className="ml-auto">
        <span className="in-black d-none d-sm-inline mr-8">
          {isSignUp ? (
            <FormattedMessage id="auth.header.alreadyHaveAnAccount" />
          ) : (
            <FormattedMessage id="auth.header.didNotRegisterYet" />
          )}
        </span>
        {isSignUp ? (
          // @ts-ignore
          <Button type="secondary" size="small" onClick={onClick}>
            <FormattedMessage id="shared.logIn" />
          </Button>
        ) : (
          // @ts-ignore
          <Button type="secondary" size="small" onClick={onClick}>
            <FormattedMessage id="shared.signUp" />
          </Button>
        )}
      </div>
    </div>
  );
}

export default AuthHeader;
