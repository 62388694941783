import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import { pathOr, path, slice } from 'ramda';
import type { AnySchema } from 'yup';

import type { WorkingHour } from 'views/shared/WorkingHourField/types';

function dateOverlapping(this: AnySchema, options: any) {
  return this.test({
    name: 'dateOverlapping',
    message: { id: 'shared.yourTimeSlotIsOverlapped' },
    test(_, { parent }: any) {
      const id = path(['id'], parent);
      const start = path(['start'], parent);
      const end = path(['end'], parent);
      const dayWeek = path(['dayWeek'], parent);

      if (start instanceof Date && end instanceof Date) {
        // Hours that place before
        let otherWeekDayHours = pathOr<WorkingHour[]>([], ['parent', dayWeek as string], options);

        const currentIndex = otherWeekDayHours.findIndex((item: WorkingHour) => item.id === id);

        otherWeekDayHours = slice(0, currentIndex, otherWeekDayHours) as WorkingHour[];

        for (let i = 0; i < otherWeekDayHours.length; i += 1) {
          const hour = otherWeekDayHours[i] as WorkingHour;

          if (hour.start instanceof Date && hour.end instanceof Date) {
            if (isEqual(start, hour.start) || isEqual(start, hour.end)) {
              return false;
            }

            if (isEqual(end, hour.start) || isEqual(end, hour.end)) {
              return false;
            }

            if (isAfter(start, hour.start) && isBefore(start, hour.end)) {
              return false;
            }

            if (isAfter(end, hour.start) && isBefore(end, hour.end)) {
              return false;
            }

            if (isBefore(hour.start, end) && isBefore(start, hour.end)) {
              return false;
            }
          }
        }
      }

      return true;
    },
  });
}

export default dateOverlapping;
