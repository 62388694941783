import { useField } from 'formik';
import type { KeyboardEvent, FocusEvent } from 'react';
import { useIntl } from 'react-intl';

import type { InputFieldProps } from './types';

// Tab, Backspace, Delete, Left, Right
const SPECIAL_KEY_CODES = [9, 8, 46, 37, 39];

const useContainer = (props: InputFieldProps) => {
  const { canHandleErrors, name, possibleSymbols } = props;

  const [field, meta, helpers] = useField(name);

  const intl = useIntl();

  const isTouchedAndError = canHandleErrors && meta.touched && meta.error;

  /**
   * On blur handler
   */
  const onBlurHandler = (event: FocusEvent<HTMLInputElement>) => {
    let formatted = field.value;

    if (typeof formatted === 'string') {
      formatted = formatted.trim();
    }

    if (/^\s*$/.test(formatted)) {
      formatted = '';
    }

    field.onBlur(event);

    if (formatted !== field.value) {
      helpers.setValue(formatted);
    }
  };

  /**
   * On key down handler
   */
  const onKeyDownHandler = (event: KeyboardEvent<HTMLImageElement>) => {
    const isSpecialKeyCode = SPECIAL_KEY_CODES.includes(event.keyCode);

    if (!isSpecialKeyCode && typeof possibleSymbols !== 'undefined' && !possibleSymbols.test(`${event.key}`)) {
      event.preventDefault();
    }
  };

  return {
    isTouchedAndError,
    intl,
    field,
    meta,
    onBlurHandler,
    onKeyDownHandler,
  };
};

export default useContainer;
