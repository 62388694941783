import compact from 'lodash/compact';
import { propOr, values } from 'ramda';
import type { AnySchema } from 'yup';

import isPresent from 'utils/isPresent';

function oneOfDaySelected(this: AnySchema, name: string) {
  return this.test({
    name: 'oneOfDaySelected',
    message: { id: 'yup.mixed.required' },
    test(_, { parent }: any) {
      const compareValue = propOr(null, name, parent);

      if (isPresent(compareValue)) return true;

      if (!isPresent(compact(values(parent)))) return true;

      return false;
    },
  });
}

export default oneOfDaySelected;
