import type { AnySchema } from 'yup';
import type { Message } from 'yup/lib/types';

import { ONLY_NUMBERS_REGEX } from 'constants/app';
import isPresent from 'utils/isPresent';

function onlyNumbers(this: AnySchema, msg: Message) {
  return this.test({
    name: 'onlyNumbers',
    exclusive: false,
    message: msg || { id: 'shared.incorrectFormat' },
    test(value: string) {
      if (!isPresent(value)) {
        return true;
      }

      return ONLY_NUMBERS_REGEX.test(value);
    },
  });
}

export default onlyNumbers;
