import Link from 'next/link';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'constants/routes';

function AuthFooter() {
  return (
    <div className="container-sm mt-auto">
      <p className="in-black text-center">
        <FormattedMessage
          id="auth.footer.links"
          values={{
            terms: (
              <Link href={ROUTES.TERMS_OF_USE.PATH}>
                <a className="main-link" target="_blank">
                  <FormattedMessage id="shared.termsOfUse" />
                </a>
              </Link>
            ),
            policy: (
              <Link href={ROUTES.PRIVACY_POLICY.PATH}>
                <a className="main-link" target="_blank">
                  <FormattedMessage id="shared.privacyPolicy" />
                </a>
              </Link>
            ),
          }}
        />
      </p>
    </div>
  );
}

export default AuthFooter;
