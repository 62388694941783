import { Alert, Button, Col, Form, Row } from 'antd';
import classNames from 'classnames';
import { FormikProvider } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import ROUTES from 'constants/routes';
import useAppRouting from 'hooks/useAppRouting';
import { isIntlMessage } from 'utils/isIntlMessage';
import AuthLayout from 'views/layouts/AuthLayout';
import AuthFooter from 'views/shared/AuthFooter';
import AuthHeader from 'views/shared/AuthHeader';
import InputField from 'views/shared/InputField';
import InputPasswordField from 'views/shared/InputPasswordField';

import useContainer from './hook';

function PageSignIn() {
  const { formik, isLoading, onErrorClose } = useContainer();

  const { goToPage } = useAppRouting();

  return (
    <div className="auth__container">
      <div className="container-sm">
        <AuthHeader onClick={goToPage(ROUTES.REGISTRATION.PATH)} />
        <FormikProvider value={formik}>
          <Row gutter={24} justify="center">
            <Col span={24} md={20} xl={16}>
              <h1 className="text-headline text-md-display mb-24 mb-md-32">
                <FormattedMessage id="auth.loginToYourAccount" />
              </h1>
              <Form layout="vertical">
                {formik.status && (
                  <Alert
                    key={formik.submitCount}
                    className="mb-16"
                    message={isIntlMessage(formik.status) ? <FormattedMessage {...formik.status} /> : formik.status}
                    type="error"
                    showIcon
                    icon={<i className="icon icon-alert" />}
                    closeIcon={<i className="icon icon-cross" />}
                    onClose={onErrorClose}
                    closable
                  />
                )}
                <InputField id="email" name="email" type="email" size="large" label={{ id: 'shared.email' }} />
                <InputPasswordField
                  id="password"
                  name="password"
                  className="mb-24"
                  label={{ id: 'shared.password' }}
                  canHandleErrors
                  hasRecovery
                  hasProgress={false}
                  onRecoveryLinkClick={goToPage(ROUTES.PASSWORD_RECOVERY.PATH)}
                />
                <div className="d-flex justify-content-end">
                  <Button
                    className={classNames('min-w-120', {
                      'ant-btn-primary': formik.dirty && formik.isValid,
                      'ant-btn-secondary': !formik.dirty || !formik.isValid,
                    })}
                    size="large"
                    onClick={formik.handleSubmit as unknown as React.MouseEventHandler<HTMLElement>}
                    disabled={isLoading}
                    loading={isLoading}
                  >
                    <FormattedMessage id="shared.logIn" />
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>
        </FormikProvider>
      </div>
      <AuthFooter />
    </div>
  );
}

PageSignIn.getLayout = (page: React.ReactNode) => <AuthLayout>{page}</AuthLayout>;

PageSignIn.title = { id: 'auth.loginToYourAccount' };

export default PageSignIn;
