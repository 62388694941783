import { MIN_PASSWORD_LENGTH } from 'constants/app';

export const hasNumber = (value: string) => /[0-9]/.test(value);
export const hasUpperCase = (value: string) => value !== value.toLowerCase();

export const getPasswordStrengthScale = (value: string) => {
  let strength = 0;
  if (value.length > 0) {
    strength += 1;
  }
  if (value.length >= MIN_PASSWORD_LENGTH) {
    strength += 1;
  }
  if (hasNumber(value) && hasUpperCase(value)) {
    strength += 1;
  }
  return strength;
};

export const getStrengthData = (value: string) => {
  const strength = getPasswordStrengthScale(value);

  if (strength === 0) {
    return {
      wrapperClass: 'main-input__message main-input__message--info',
      iconClass: 'icon icon-info',
      text: { id: 'shared.passwordMustContain' },
      step: 1,
    };
  }
  if (strength === 1) {
    return {
      wrapperClass: 'main-input__message',
      iconClass: 'icon icon-info in-red',
      text: { id: 'shared.useMinimumPasswordLength' },
      step: 2,
    };
  }
  if (strength === 2) {
    return {
      wrapperClass: 'main-input__message',
      iconClass: 'icon icon-info in-yellow',
      text: { id: 'shared.youCanUseUppercaseCharacters' },
      step: 3,
    };
  }
  return {
    wrapperClass: 'main-input__message',
    iconClass: 'icon icon-checked in-green',
    text: { id: 'shared.yourPasswordIsStrongEnough' },
    step: 4,
  };
};
