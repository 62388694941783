import type { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';

import bothNumbersAndLetters from './validations/bothNumbersAndLetters';
import isHexColor from './validations/css';
import dateComparison from './validations/dateComparison';
import dateEquality from './validations/dateEquality';
import dateOverlapping from './validations/dateOverlapping';
import oneOfDaySelected from './validations/oneOfDaySelected';
import onlyNumbers from './validations/onlyNumbers';
import withTwoNumberValues from './validations/withTwoNumberValues';
import yupLocale from './yupLocale';

declare module 'yup' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars -- not used
  interface StringSchema<TType, TContext, TOut> {
    isHexColor(color: string): this;
    bothNumbersAndLetters(): this;
    onlyNumbers(msg?: MessageDescriptor): this;
    withTwoNumberValues(msg?: MessageDescriptor): this;
  }
}

yup.setLocale(yupLocale);

yup.addMethod(yup.string, 'isHexColor', isHexColor);
yup.addMethod(yup.string, 'bothNumbersAndLetters', bothNumbersAndLetters);
yup.addMethod(yup.string, 'onlyNumbers', onlyNumbers);
yup.addMethod(yup.string, 'withTwoNumberValues', withTwoNumberValues);
yup.addMethod(yup.mixed, 'dateEquality', dateEquality);
yup.addMethod(yup.mixed, 'dateOverlapping', dateOverlapping);
yup.addMethod(yup.mixed, 'dateComparison', dateComparison);
yup.addMethod(yup.mixed, 'oneOfDaySelected', oneOfDaySelected);

export default yup;
