import { useField } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

import { getStrengthData } from 'utils/getPasswordStrength';

import FormattedOrRawMessage from '../FormattedOrRawMessage';

import type { InputPasswordProps } from './types';

const useContainer = (props: InputPasswordProps) => {
  const { canHandleErrors, name, hasProgress } = props;

  const [field, meta] = useField(name);

  const intl = useIntl();

  const isTouchedAndError = canHandleErrors && meta.touched && meta.error;

  const strengthData = getStrengthData(field.value);

  /**
   * Get help
   */
  const getHelp = () => {
    if (hasProgress) {
      return (
        <p className={strengthData.wrapperClass}>
          <i className={strengthData.iconClass} />
          <FormattedMessage {...strengthData.text} />
        </p>
      );
    }

    if (!hasProgress && isTouchedAndError) {
      return <FormattedOrRawMessage message={meta.error} />;
    }

    return undefined;
  };

  return {
    isTouchedAndError,
    intl,
    field,
    help: getHelp(),
    strengthData,
  };
};

export default useContainer;
