import { is } from 'ramda';
import type { AnySchema } from 'yup';

const HEX_COLOR_REGEX = /^#([A-Fa-f0-9]{6})$/;

function isHexColor(this: AnySchema) {
  return this.test('isHexColor', { id: 'yup.string.isHexColor' }, (value) => {
    if (!is(String, value)) return true;

    return HEX_COLOR_REGEX.test(value);
  });
}

export default isHexColor;
