import { is } from 'ramda';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { FormattedOrRawMessageProps } from './types';

function FormattedOrRawMessage({ message }: FormattedOrRawMessageProps) {
  if (typeof message === 'string') {
    return <span>{message}</span>;
  }

  if (typeof message === 'object' && is(String, message.id)) {
    return <FormattedMessage {...message} />;
  }

  return <span>Error: Unexpected empty message</span>;
}

export default FormattedOrRawMessage;
