import { useFormik } from 'formik';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import useDispatchAction from 'hooks/useDispatchAction';
import applyMiddlewares from 'lib/applyMiddlewares';
import withIntlLocales from 'lib/withIntlLocales';
import validationSchema from 'lib/yupLocalised/schemas/signInSchema';
import { userSignIn } from 'state/concepts/session/actions';
import { userLoginEndpoint } from 'state/concepts/session/endpoints';
import { loadingSelector } from 'state/data/selectors';
import type { RootState } from 'state/store/types';

const useContainer = () => {
  const isLoading = useSelector((state: RootState) => loadingSelector(state, userLoginEndpoint.endpoint));

  /**
   * On Submit
   */
  const onSubmit = useDispatchAction(userSignIn);

  /**
   * Formik initialization
   */
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit,
  });

  const onErrorClose = useCallback(() => formik.setStatus(undefined), [formik]);

  return {
    onErrorClose,
    formik,
    isLoading,
  };
};

export const getStaticProps = applyMiddlewares(withIntlLocales('auth'));

export default useContainer;
