import type { AnySchema } from 'yup';

import { BOTH_NUMBERS_AND_LETTERS_REGEX } from 'constants/app';
import isPresent from 'utils/isPresent';

function bothNumbersAndLetters(this: AnySchema) {
  return this.test({
    name: 'bothNumbersAndLetters',
    exclusive: false,
    message: { id: 'shared.incorrectFormat' },
    test(value: string) {
      if (!isPresent(value)) {
        return true;
      }

      return BOTH_NUMBERS_AND_LETTERS_REGEX.test(value);
    },
  });
}

export default bothNumbersAndLetters;
