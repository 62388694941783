import isNaN from 'lodash/isNaN';
import { is, split } from 'ramda';
import type { AnySchema } from 'yup';
import type { Message } from 'yup/lib/types';

import isPresent from 'utils/isPresent';

function withTwoNumberValues(this: AnySchema, msg: Message) {
  return this.test({
    name: 'withTwoNumberValues',
    exclusive: false,
    message: msg || { id: 'shared.withTwoNumberValues' },
    test(value: string) {
      if (!isPresent(value)) {
        return true;
      }

      const result = split('.', value);
      const firstValue = parseFloat(result[0]);
      const secondValue = parseFloat(result[1]);

      if (isNaN(firstValue) || isNaN(secondValue)) return false;

      return is(Number, firstValue) && is(Number, secondValue);
    },
  });
}

export default withTwoNumberValues;
