import { Form, Input } from 'antd';
import type { KeyboardEventHandler } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import FormattedOrRawMessage from 'views/shared/FormattedOrRawMessage';

import useContainer from './hook';
import type { InputFieldProps } from './types';

function InputField(props: InputFieldProps) {
  const { id, label, placeholder, type, size, isRequired, prefix, additionalClassNames, disabled } = props;

  const { isTouchedAndError, intl, field, meta, onBlurHandler, onKeyDownHandler } = useContainer(props);

  const help = isTouchedAndError ? <FormattedOrRawMessage message={meta.error} /> : undefined;

  const validateStatus = isTouchedAndError ? 'error' : undefined;

  return (
    <Form.Item
      htmlFor={id}
      label={
        label && (
          <>
            <FormattedMessage {...label} />
            {isRequired && <span className="in-red">*</span>}
          </>
        )
      }
      validateStatus={validateStatus}
      help={help}
      className={additionalClassNames.form}
    >
      <Input
        id={id}
        placeholder={placeholder && intl.formatMessage(placeholder)}
        {...field}
        onBlur={onBlurHandler}
        onKeyDown={onKeyDownHandler as unknown as KeyboardEventHandler<HTMLInputElement>}
        type={type}
        size={size}
        prefix={prefix}
        disabled={disabled}
      />
    </Form.Item>
  );
}

InputField.defaultProps = {
  canHandleErrors: true,
  additionalClassNames: {
    form: undefined,
  },
};

export default InputField;
